.blue-skin{

}

.red-skin{

}

.green-skin{

}

.purple-skin{

}

.dark-skin{

}

.grey-skin{

}

.mdb-skin{

}

.deep-orange-skin{

}

.graphite-skin{

}

