@media print {
    .article-content .sidebar, .articleRowButtons, .buttons-article, .mainNav.navbar {
        display:none;
    }

    header {
        .navbar-brand {

            margin-left: 50px;
            margin-top: 15px;

            .print {
                max-width: 500px;
            }
        }
    }
}

@media (min-width: 1200px) {
    .container {
        width: 1240px;
    }
}

.mobile-menu-btn {
    margin-top: 20px;

    i {
        color: #ccc;
    }

}

@media screen and (max-width: 767px) and (min-width: 360px) {
  .mobile-menu-btn {
    margin: 15px !important;
    padding-top: 2px;
  }
}

.article-list, h3.col-xs-12, .article-content {
    padding-left: 25px;
    padding-right: 25px;
}

.article-content {
  h3, h4 {
    font-weight: 700;
  }
}

p.media_teaser_text {
    margin-top: 15px;
}

.status_copyright {
		margin-top: -10px;
		color: #bbbbbb;
}

.articleRowButtons {

    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    padding: 0px;
    margin: 0 15px 35px;

    .articleMediaButtons {

        text-align: right;

        span {
            cursor: pointer;
        }

        .disabled {
            opacity: 0.3;
        }

        &.card-block {
            padding: 12px 0;
        }

        i.fa {

            padding: 0 10px;
            font-size: 24px;
        }

        .socialIcons {
            margin-top: 5px;
            a {
                .fa {
                    padding-right: 10px;
                }
            }
        }
    }
}

.csArticle-page_main {
    .group {
        .csMedia-left {
            margin-right: 0;
        }

        .csMedia-right {
            margin-left: 0;
        }
    }

    img.imageBoxAuthor {
        width: 60%;
        text-align: center;
        margin: 0 auto;
    }

    img.imageBoxLogo {
        width: 60%;
    }

    a {
        button.btn.infoButton {
            background-color: #ef9648 !important;
            font-weight: bold;
            color: #fff;
            border-radius: 2px;
            font-size: 0.8rem;
            padding: 0.85rem 2.13rem;
        }
    }
}

li.breadcrumb-item.shorten-long-text {
    width: 380px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: inherit;
}

main {
    padding-top: 180px !important;

    .breadcrumb {
        margin-bottom: 10px;

        .breadcrumb-item {
            text-decoration: underline;
        }

        .breadcrumb-item + .breadcrumb-item::before {
            content: ">";
        }
    }
}

header {

    .navbar  {

        background-color: #fff !important;

        .navbar-brand {
            img {
                height: 70px;
                padding: 10px 20px;
            }
        }

        input[type=search], input[type=search]:focus, input[type=search]:hover  {
            border: 1px solid #ccc;
            margin-top: 15px;
            padding-top: 0.75rem;
            padding-left: 0.5rem;
            color: #000 !important;
            background-color: #fff;
        }

        ::placeholder {
            padding-top: 3px !important;
            color: #ccc !important;
            opacity: 1; /* Firefox */
        }
    }
}

.mainNav {

    &.navbar {

        box-shadow: none;
        text-align: center;
        border-bottom: 2px solid #ccc;
        margin-left: 15px;
        margin-right: 15px;
        margin-top: 15px;
        padding: 5px 0;

        ul {

            .btn-group {

                button {
                    background: #fff;
                    color: #373a3c !important;
                    box-shadow: none;
                    font-size: 16px;
                    margin: 0 10px;
                    @media (max-width: 1200px) {
                      margin: 0;
                      padding: 12px 24px;
                    }
                }

                button:focus, button:hover {
                    background-color: #ccc !important;
                    color: #fff !important;
                }

                button:after {
                    border: none;
                }

                .dropdown-menu {
                    a {
                        color:#000 !important;
                        text-transform: uppercase;
                    }

                    span {
                        color:#ccc !important;
                        text-transform: uppercase;
                    }
                    .dropdown-toggle::after{
                        content: none !important;
                    }

                    div.submenu.dropdown-menu {
                        display: none;
                        position: relative;
                        left: 30%;
                        top: -7px;
                    }




                }


            }

            .btn-group + .btn-group {
                border-left: 1px solid #ccc;
            }


        }

    }
}

.csRating {

    .card-block {
        padding: 1rem 1rem 0;

        .ratingLabel {
            margin-right: 10px;
        }

        .rating {
            float: right;

            .amber-text {
                color: #e6007c !important;
            }
        }
    }
}

footer {

    &.page-footer {
        background-color: #bbb !important;

        .footer-copyright {
            background-color: #bbb !important;
            padding: 0 40px;

            .containter-fluid {
                border-top: 2px solid #fff;
            }
        }
    }
}

.articleInfo {

    padding-left: 25px;
    padding-right: 25px;

    .content {

        border-bottom: 1px solid  #ccc;

        h4 {
            margin-top: 1ex;
            clear: both;
            font-family: universimed_1;
        }

        ul {

            &.csDefault-list  {
                -moz-padding-start: 0px;
                -webkit-padding-start: 0px;
                -khtml-padding-start: 0px;
                -o-padding-start: 0px;
                padding-start: 0px;

                li {
                    list-style: none;

                    p {
                        font-weight: 300;
                        color: #aaa;
                        font-family: universimed_1;

                        .bq-title {
                            margin-top: 1ex;
                            clear: both;
                            color: #000;
                        }
                    }
                }
            }

            .title {
                margin-top: 1ex;
                clear: both;
                font-family: universimed_1;
            }

            .data {
                font-weight: 100;
                color: #aaa;
            }
        }

        .group {

            text-align: center;
            margin-bottom: 15px;
            margin-top: 25px;

            a {
                background-color: #ef9648 !important;
                font-weight: bold;
                color: #fff;
                border-radius: 2px;
                font-size: 0.8rem;
                padding: 0.85rem 2.13rem;

                i.fa-external-link {
                    display: none;
                }

            }

        }

        .articleInfoButton {
            text-align: center;
            margin-bottom: 15px;

            .infoButton {
                background-color: #ef9648 !important;
                font-weight: bold;

                a {
                    color: white;
                }
            }


        }

    }
    a {
        button.btn.infoButton {
            background-color: #ef9648 !important;
            font-weight: bold;
            color: #fff;
            border-radius: 2px;
            font-size: 0.8rem;
            padding: 0.85rem 2.13rem;
        }
    }
}

.csPage_navigation.pagination {
    width: 100%;

    .previous_link {
        float: left;
    }

    .next_link {
        float: right;
    }

    a {
        text-decoration: underline;
    }
}

.darkSlider {
    color: #000 !important;
}

.noTextSlider {
    text-indent: -9999px;
    height: 100px;
    width: 600px;
    white-space: nowrap;
}
.video__embed.center{
    iframe.vimeo {
        min-height: 420px;
        width: 100%;

        @media (max-width: 1200px) {
            min-height: auto;
        }
    }
}


.video__embed.left {
    iframe.vimeo{
        //min-height: 420px;
        width: 100%;

    @media (max-width: 1200px) {
        min-height: auto;
    }
    }
}

.csSmartNav {
    .mp-menu {
        ul {
            li {
                a.no-link {

                    small.mini {
                        text-transform: lowercase;
                    }
                }
            }
        }
    }
}

.registration {

    //border-right: 1px #000 solid;
    padding: 2em;

    .oakLink{
        label {
            top: 0.2rem;
            cursor: pointer;
        }
    }

    .custom_container{
        display: inline-block;
        border: 1px solid #ccc;
        padding: 7px 15px 15px;
        height: 35px;
        width: 100%;
        margin-bottom: 15px;
        cursor: pointer;

        .custom_label{
            margin-right: 10px;
        }

    }

    .rightBorder {
        border-right: 1px #000 solid;
    }

    .bottom {
        border-bottom: 1px #000 solid;
        padding-bottom: 30px;
        margin-bottom: 20px;
    }

    h5 {
        font-weight: bold;
        color: #000;

        &.title {
            font-size: 24px;
            margin-top: 15px;

            &.section{
                margin-bottom: 10px;
            }
        }
    }

    div.noArztOption {
        display: none;
    }

    input[type=text], input[type=email], input[type=password] {
        border: 1px solid #ccc;
        padding: 20px 15px 15px;
        height: 30px;


        &:-ms-input-placeholder { /* IE10–11 */
            padding-top: 3px !important;
        }
    }

    .select {
        border: 1px solid #ccc;
        overflow: hidden;
        height: 35px;
        width: 100%;
        position: relative;
        display: block;
        margin-bottom: 35px;
        margin-top: -10px;
    }

    select{
        height: 35px !important;
        padding: 0 15px;
        border: 0;
        font-size: 16px;
       -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        cursor: pointer;
        margin-top: -2px;

        &::-ms-expand{
            display: none;
        }
    }
    .select:after {
        content:"\f0dd";
        font-family: FontAwesome;
        color: #ccc;
        padding: 0px 7px;
        position: absolute; right: 0; top: 0;
        background: transparent;
        z-index: 1;
        text-align: center;
        width: 8%;
        height: 100%;
        pointer-events: none;
        box-sizing: border-box;
        top: 0px;
        border-left: 1px #ccc solid;
        font-size: 18px;
    }

    .radio {

        input[type=radio] {
            visibility: inherit;
            left: 0;
            margin-right: 10px;
            position: relative;
        }

        label {
            position: static;
            color: #000;
            font-size: 18px;
            line-height: 30px;
        }
    }

    .user-login {

        min-height: 150px !important;

        p {
            margin-bottom: 0;
            font-size: 18px;
            padding-left: 5px;

        }

        button {
            position: absolute;
            bottom: 0;
            background: #ccc;
            font-size: 17px;
            padding: 10px 15px;
            text-transform: uppercase;
            box-shadow: none;

            &.orange {
                background: #f29100;
            }
        }
    }

    .fachgebiet_max{
        margin-top: -15px;
        margin-bottom: 10px;
    }

    .dropdown.fachgebiet{
        span.caret{
            display: none;
        }

        &:after{
            content: "\f0dd";
            font-family: FontAwesome;
            color: #ccc;
            padding: 1px 7px;
            border-left: 1px solid  #ccc;
            font-size: 18px;
            top: 0;
            width: 8%;
            height: 70%;
            margin-left: -8.25%;
            position: absolute;
        }

        ul{
            min-width: unset;
            max-width: unset;
            width: 825px !important;
            min-height: unset;
            max-height: unset;
            height: 250px;
            left: -150px;
            padding: 0.75rem 0.5rem;

            li{
                float: left;
                width: 200px;
                height: 25px;
                overflow: hidden;
                span{
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: inline-block;
                    max-width: 100%;
                    padding-top: 2px;

                    label{
                        top: 2px;
                    }
                }

                &.disabled{
                    display: inherit;
                    float: left;
                    width: 800px;
                    height: 25px;
                    border-top: 1px solid #eee;
                    margin-top: 10px;
                    margin-bottom: 2px;

                    label{
                        padding-left: 0;
                        &:before{
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

.navbar-nav {
    .nav-item {
        a {
            span.avatar-icon {
                padding: 15px 45px 0px 15px !important;
                i.fa-user {
                    color: #ccc !important;
                    font-size: 30px;
                }
            }
        }
    }
}

.userBanner {
    position:fixed;
    width: 268px;
    z-index: 1000;
    //top: 180px;
    //right: 350px;

    top: 0;
    margin-top: 175px;
    left: ~"calc(50% + 620px)";
    left: ~"-webkit-calc(50% + 620px)";

    iframe {
        min-height: 600px;
    }
}

.customQuickbar{
    float: right;
    margin-top: 17px;
    border-right: 1px solid #ccc;
    padding-right: 10px;
}

@media screen and (max-width: 1024px) {
    .customQuickbar{
        display: none;
    }
}

.quickbar-item {
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    text-align: right;
}

@media screen and (max-width: 767px) and (min-width: 260px) {
  .userBanner {
    position: initial;
    width: 160px;
    z-index: 1000;
    top: inherit;
    left: inherit;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0;
  }
}

.main_banner {
    display: flex;
    justify-content: center;
    img {
        width: 728px;
        margin: 0 auto;
        height: 90px;
    }

    &.skyscraper{
        position: absolute;
        right: 0;
        margin-right: -160px;
        margin-top: 15px;
    }
}

select.ui-datepicker-year{
    display: inline !important;
}

.adContainer{
    text-align: center;
    .iframeAds {
        width: 310px;
        min-height: 270px;
        @media screen and (max-width: 1240px) and (min-width: 768px){
            width: 100%;
        }

        @media screen and (max-width: 767px) {
            width: auto;
        }



    }
    &.slider_ad{
        .iframeAds {
            width: 1240px;
            height: 530px;

            @media screen and (max-width: 768px) {
                width: 100%;
            }
        }

        @media screen and (max-width: 768px) {
            height: 30ex;
        }

    }

    &.long_banner{
        .iframeAds {
            width: 740px;
            height: 110px;
            min-height: 100px;
            // @media screen and (max-width: 1024px) and (min-width: 768px) {
            @media screen and (max-width: 767px) and (min-width: 260px) {
                height: 65px;
                min-height: auto;
            }
        }
    }

    &.leaderboard{
        width: 728px !important;
        height: 90px !important;
    }
}

.leaderboard{
    text-align: center;
    @media screen and (max-width: 991px) {
        max-width: 100%;
        padding: 0 15px;
        img{
            width: 100% !important;
            height: auto !important;
        }
    }

    div[id*='oba-player-divx']{
        width: 100% !important;
    }
}

.carousel {
    &.slide {
        @media screen and (max-width: 768px) {
            margin-top: 12ex;
        }

    }
}

#validationData {
    display: none;
}


.webui-popover-password-popover {

    @yellow    : #F9D04E; // yellow
    @red       : #F03D58; // red
    @green     : #3EBE22; // green
    @orange    : orange;
    @dark-green: #26724C;

    margin-left: 15px !important;
    font-size  : 12px;

    .fa-times {
        color: @red;
    }

    .fa-check {
        color: @green;
    }

    .quality {
        border-bottom: 4px solid;
    }

    .quality0 {
        border-bottom-color: @red;
    }

    .quality1 {
        border-bottom-color: @red;
    }

    .quality2 {
        border-bottom-color: @yellow;
    }

    .quality3 {
        border-bottom-color: @yellow;
    }

    .quality4 {
        border-bottom-color: @green;
    }

}

.csTabs{
    .nav-tabs{
        background-color: transparent !important;
        margin-bottom: 0px;

        a{
            color: #000;

            &.active{
                border-bottom: 2px solid #000 !important;
                color: #000;
            }

            &:hover{
                border-bottom: 2px solid #000 !important;
            }
        }
    }
}

.passwordValidation{
    padding: 0 30px;
    li{
        color: red;
        font-size: .9rem;
        margin-bottom: 0;
    }

}


// ePapers / Archiv
.epapers_list {
    padding-top: 25px;

    h3 {
        justify-content  : center;
        display          : flex;
        text-decoration  : none;
        color            : #000  !important;
        font-size        : 24px;
        margin-bottom    : 20px;
    }

    .covers {
        padding-top: 25px;
        display    : inline-block;

        .cover {
            margin-bottom: 25px;
            display      : inline-block;

            .image,
            .name,
            .date {
                display        : flex;
                justify-content: center;
            }

            .image {
                img {
                    width        : auto;
                    height       : 300px;
                    border-radius: 0;
                    margin-bottom: 1ex;
                }
            }

            .name {
                font-size    : 1.2rem;
                font-weight  : 900;
                margin-bottom: 5px;
            }

            .date {
                font-size: .75em;
            }
        }
    }

    form {

        div.align-right,
        select.align-right {
            float: right;
        }

        .select-wrapper {
            background-color: white;
            border-radius   : 1em;
            padding         : 0 20px;
            max-width       : 100%;
            min-width       : 40%;
            float           : left;
            margin-right    : 20px;
            cursor          : pointer;

            &.epaper-filter-select {
                width    : 30%;
                max-width: inherit;
                min-width: inherit;
            }

            ul {
                max-width: 100%;
            }

            &.long {
                max-width: 60%;
                min-width: 50%;
            }

            &.selected {
                border    : 1px #000 solid;
                min-height: 40px;

                .custom_container {
                    float: left;
                    width: 100%;

                    span.custom_label {
                        margin-right: 10px;
                        padding-top : 8px;
                        float       : left;

                        &.more {
                            top     : 3px;
                            position: absolute;
                        }
                    }

                }
            }

            input[type=text] {
                margin-bottom: 0;
                border-bottom: none;
                max-height   : 40px;
            }

            span.caret {
                right    : 16px;
                font-size: 0;
                top      : 14px;

                &::before {
                    font-family   : FontAwesome;
                    content       : "\f078";
                    font-size     : 10px;
                }
            }
        }

        input[type=checkbox]+label {
            top: 2px;
        }

        input[type=checkbox]:checked+label {

            width        : 20px;
            height       : 20px;
            padding-left : 0;
            margin-right : 13px;
            border-radius: 0.3em;
            margin-bottom: -2px;

            &::before {
                top                     : 0;
                left                    : 1px;
                width                   : 8px;
                height                  : 13px;
                border-top              : 2px solid transparent;
                border-left             : 2px solid transparent;
                border-right            : 2px solid #fff;
                border-bottom           : 2px solid #fff;
                -webkit-transform       : rotateZ(37deg);
                transform               : rotateZ(37deg);
                -webkit-transform-origin: 100% 100%;
                transform-origin        : 100% 100%;
            }

        }
    }

}

p {
    &.style-5 {
        display: none;
    }
}
.link-style-5 {
    margin: 0;
    margin-left: 0 !important;

    i {
        display: none;
    }
}

.configurable-widget {
    .asmlink {
        .asmbannerimg {
            width: 100% !important;
            height: auto !important;
        }
    }
}

.emptyBanner, .emptyLdbBanner {
    display: none !important;
}