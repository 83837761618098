main {
    padding-top: 160px;
}

header {

    .navbar  {

        background-color: #fff !important;

        .navbar-brand {
            img {
                height: 80px;
            }
        }

        input[type=search], input[type=search]:focus, input[type=search]:hover  {
            border: 1px solid #ccc;
            margin-top: 15px;
            padding-top: 0.75rem;
            padding-left: 0.5rem;
            color: #000 !important;
            background-color: #fff;
        }

        ::placeholder {
            padding-top: 3px !important;
            color: #ccc !important;
            opacity: 1; /* Firefox */
        }
    }
}

.mainNav {

    .navbar {

        box-shadow: none;
        text-align: center;
        border-bottom: 2px solid #ccc;
        margin-left: 15px;
        margin-right: 15px;
        padding: 5px 0;

        ul {

            display: inline-block;
            width: 100%;

            .nav-item {

                min-width: 175px;

                a {
                    color: #bbb !important;
                    text-transform: uppercase;
                    text-align: center;

                    &.noLast {
                        border-right: 1px solid #ccc;
                    }
                }
            }
        }

    }
}

footer {

    &.page-footer {
        background-color: #bbb !important;

        .footer-copyright {
            background-color: #bbb !important;
            padding: 0 40px;

            .containter-fluid {
                border-top: 2px solid #fff;
            }
        }
    }

    ul {
        &.csFooter-link {
            li {
                a, span {
                    margin-left: 30px;
                    font-family: 'myriad-pro-condensed', sans-serif;
                    font-size: .82em;
                    line-height: 1.3em;
                    font-weight: bold;
                    display: block;
                    padding: 0;
                    text-decoration: none;
                    text-transform: uppercase;
                }

                span {
                    color: #ccc;
                }

                ul {
                    &.sub-nav {
                        li {
                            float: none;
                            margin: 2px 0 0 0;
                        }

                        a, span {
                            font-weight: bold;
                            text-transform: none;
                        }
                    }
                }
            }
        }
    }

    .footer-copyright {
        height: auto !important;
        line-height: normal !important;

        .containter-fluid {
            padding-top: 35px;

            h4 {
                text-align: left;
            }

            &.copyright {
                line-height: 120px;
            }

            &.social {

                .container-social {
                    margin-top: 5px;
                    font-size: 1.2rem;

                    .facebook, .xing, .linkedin, .docCheck {
                        text-align: left;
                    }
                }
            }

            &.newsletter {
                h4 {
                    text-transform: uppercase;
                    font-size: 1rem;
                    text-align: left;
                }

                .mail {
                    border: 1px solid #fff;
                    color: #fff;
                    height: 35px;
                }

                .submitButton {
                    float: left;
                    margin-left: 15px;

                    .submit {
                        padding: 10px 12px 10px 12px;
                        font-size: 0.8rem;
                        letter-spacing: 1px;
                        font-weight: bold;
                        height: 35px;
                        color: #bbb;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}