@font-face {
    font-family: "universimed_0";
    src: url("/custom/fonts/3771EE_0_0.eot");
    src: url("/custom/fonts/3771EE_0_0.eot?#iefix") format("embedded-opentype"), url("/custom/fonts/3771EE_0_0.woff") format("woff");
    font-weight: normal;
    font-style: normal
}

@font-face {
    font-family: "universimed_1";
    src: url("/custom/fonts/3771EE_1_0.eot");
    src: url("/custom/fonts/3771EE_1_0.eot?#iefix") format("embedded-opentype"), url("/custom/fonts/3771EE_1_0.woff") format("woff");
    font-weight: normal;
    font-style: normal
}

@font-face {
    font-family: "universimed_2";
    src: url("/custom/fonts/3771EE_2_0.eot");
    src: url("/custom/fonts/3771EE_2_0.eot?#iefix") format("embedded-opentype"), url("/custom/fonts/3771EE_2_0.woff") format("woff");
    font-weight: normal;
    font-style: normal
}

@font-face {
    font-family: "universimed_3";
    src: url("/custom/fonts/3771EE_3_0.eot");
    src: url("/custom/fonts/3771EE_3_0.eot?#iefix") format("embedded-opentype"), url("/custom/fonts/3771EE_3_0.woff") format("woff");
    font-weight: normal;
    font-style: normal
}

@font-face {
    font-family: "universimed_4";
    src: url("/custom/fonts/3771EE_4_0.eot");
    src: url("/custom/fonts/3771EE_4_0.eot?#iefix") format("embedded-opentype"), url("/custom/fonts/3771EE_4_0.woff") format("woff");
    font-weight: normal;
    font-style: normal
}

body {
	font-family: universimed_3;

	p {
		-webkit-font-smoothing: antialiased;
		font-size: 1.1rem;
	}

	background-color: #ececec;

	.unniversimed-container {
		background-color: white;

		header {
			.navbar {
				box-shadow: none;
				border: none;

				/*li {

						&:before {
							content: "";
							border-left-color: #AAA;
							border-left-style: solid;
							border-left-width: 1px;
						}

					&:first-child {


							&:before  {
								border:none;
							}

					}
				}*/
			}
		}

		main {
			padding-top: 25ex !important;
		}

		@media (max-width: 1024px) {
			main {
				padding-top: 20ex !important;
			}
		}

		@media print {
			main {
				padding-top: 0 !important;
			}
		}
	}

	// LUKAS CHANGES

	div.html-string {
		visibility:hidden;
	}

	div.html-string > * {
		visibility:visible;
	  }

	.source {
		margin-top: 3px;
		color: #bbbbbb;
	}

	h4.newsl_univ {
		color: #ef9648;
	}

	p.teaserStrapLine {
		margin-bottom: -8px;
		margin-top: 6px;

	}

	h4.h4-responsive.straplineArticleHead {
		text-align: center;
			width: 80%;
		margin-left: auto;
		margin-right: auto;
		font-family: universimed_1;
		margin-top: 1ex;
		margin-bottom: -10px;
	}

	p.p-responsive.date{
		text-align: center;
	}

	h5.teaserStrapLine {
		color: white;
	}

	.csFigCaption {
		margin-top: -16px;
	}

	.csArticle-page_main .group .csMedia-left {
		margin-right: 10px;
	}

	p.teaserStrapLine {
		font-weight: 800 !important;
	}

	div.contact {
		.authorNewLine {
			display: block;
		}
		font-size: 0.8rem;
		padding-left: initial;
	}

	span.name.author{
		margin-top:auto;
		font-size: 0.8rem;
		text-align: right;
	}

	img.img-fluid.authorImage{
		width: 80%;
	}

	picture.author{
		margin-top: auto !important;
		text-align: -webkit-center;
	}

	/*Link-Styles*/
	a.link-style-1 {
		background-color: #ef9648 !important;
		font-weight: bold;
		color: #fff;
		border-radius: 2px;
		font-size: 0.8rem;
		padding: 0.85rem 2.13rem;
		text-transform: uppercase;
			i.fa.fa-external-link{
				display: none
			}
	}

	a.link-style-2 {

	}
	a.link-style-3 {
		position: inherit;
		background: #ccc;
		font-size: 17px;
		padding: 10px 15px;
		text-transform: uppercase;
		box-shadow: none;
		color: #fff !important;
		transition: .2s ease-out;
			i.fa.fa-external-link{
				display: none
			}
	}
	a.link-style-3:hover{
		background-color: #252f41 !important;
	}

	a.link-style-4 {
		font-family: universimed_1;
		text-transform: uppercase;
		font-size: 18px;
			i.fa.fa-external-link{
				display: none
			}
	}
	a.link-style-4:hover{
		color: #ef9648;
	}

	a.link-style-4::before {
		content: "+";
		color: #ef9648;
		font-size: 1.3rem;
		margin-right: 0px;
		text-shadow: none;
		font-family: universimed_1;
	}

	a.link-style-5 {
		color: #ef9648;
	}

	div.customImageText {
		background-color: #C1C1C1;
		text-align: center;
		padding-top: 5px;
	}

	span.customImageText{
		color: #FFFFFF;
	}

	//orange-plus-icon in text 
	p.plus-icon:before {
		content: "+";
		color: #ef9648;
		font-size: 1.3rem;
		margin-right: 0.2em;
		text-shadow: none;
		font-family: universimed_1;
	}

	// END LUKAS CHANGES

	.keyvisual {

		margin-top: 2ex;

		.type{
			float: left;
			font-weight: 100;
			font-size: 0.8rem;
			color: #aaa;
		}

		.date{
			float: right;
			font-weight: 100;
			font-size: 0.8rem;
			color: #aaa;
		}
	}

	.article-content {
		position: relative;
		margin-top: 3ex;

		&:before {
			content:"";
			border-top: #aaa 2px solid;
			display:block;
			padding-top: 2ex;
			margin-right: 1.5em;
			margin-left: 2em;
		}
		.article-content-divider {
			border-left: #aaa 1px solid;
			border-right: #aaa 1px solid;
			position: absolute;
			top: 2ex;
    		bottom: -7ex;
		}

		.breadcrumb {
			padding-left: 2em !important;
			padding-top: 0 !important;
			padding-bottom: 0.5ex !important;
		}

		.article-header {
			padding: 2em;
			margin-top: 1ex;
			padding-top: 3ex;
		}

		.article-content-main {

			padding-left: 2em;
			margin-top: -5ex;


			h1 {
				&.h1-responsive {
					text-align: center;
					font-size: 4rem;
					width: 80%;
					margin-left: auto;
					margin-right: auto;
					font-family: universimed_1;
					margin-top: 1ex;

					&:after {
						content:"";
						width: 15rem;
						border-bottom: #666 1px solid;
						display: block;
						margin-left: auto;
						margin-right: auto;
						margin-top: 1ex;
					}
				}

			}

			h3 {
				&.h3-responsive {
					font-weight: 100;
					font-size: 1.5rem;
					text-align: center;
					width: 75%;
					margin-left: auto;
					margin-right: auto;
					margin-top: 3.5ex;
					margin-bottom: 3.5ex;

					&:after {
						content:"";
						width: 15rem;
						border-bottom: #666 1px solid;
						display: block;
						margin-left: auto;
						margin-right: auto;
						margin-top: 3.5ex;
					}

				}
			}
		}

		aside {

			margin-top: -9ex;

			&:before {
				content: "";
				border-left: #aaa 1px solid;
				border-right: #aaa 1px solid;
				position: absolute;
				//top: 0ex;
				//bottom: -7ex;
				height: 101%;
				left: 0;
			}

			.editorial-articles {
				margin-bottom: 1ex;
				margin-top: 14px;

				&:before {
					border: none;
				}

				.article {

					border-top-color: #AAA;
					border-top-style: solid;
					border-top-width: 1px;
					border-left: none !important;
					margin-top: 0 !important;

					margin-left: 0.5em;
					margin-right: 0.5em;

					&:first-child {
						padding-top: 0;
					}

					article {
						margin-top: 2ex;

						padding-left: 0 !important;
						padding-right: 0 !important;

						&:first-child {
							margin-top: 0;
						}
					}

					&:first-child {
						border:none;
					}
				}


			}

			.full-image {
				margin: 0.5em;
				border-top-color: #AAA;
				border-top-style: solid;
				border-top-width: 1px;
				margin: 0.5em;
				padding-top: 2ex;

				&.banner {
					width: auto;
				}

				&.userBanner {
					border-top: 0;
	  				position: fixed;
	  				right: 12px;
	  				width: 268px;
  					top: -20px;
 					z-index: 1000;

					iframe {
						min-height: 600px;
					}
				}

				.bannerContent {
					padding: 20px;
					background-color: #e6e5e6;
					margin-top: 10px;

					h3 {
						font-family: universimed_1;
    					margin: 40px 25px;
    					font-weight: bold;
    					font-size: 24px;
						text-align: center;
						word-break: break-word;
					}
				}
			}
		}
	}

	.articleRowButtons {
		border-top: none;
		margin-bottom: 2ex;

		.buttons-content {
			border-top: 1px solid #ccc;
		}
	}


	.csRating {

		.card-block {
			padding: 1rem 0rem 0;
			font-family: universimed_1;

			.ratingLabel {
				margin-right: 10px;
			}

			.rating
			{
				.amber-text {
					color: #ef9648 !important;
				}

				&.rating-active
				{
					float: right;


					&:hover {
						li {
							color: #ef9648;

							&:hover {
								color: #ef9648;
								cursor: pointer;

								& ~ li {
									color: #999999;
								}
							}
						}
					}


				}
			}
		}
	}

	.authors-article {

		padding-left: 1em;

		.author {
			margin-top: 4ex;

			i {
				font-size: 5rem;
				text-align: center;
				vertical-align: middle;
			}

			.name {
				font-family: universimed_1;
			}

			.info {
				font-size: 0.85rem;
			}

			.contact {
				a {
					text-decoration: underline;
				}
			}
		}
	}

	.buttons-article {
		font-family: universimed_1;
		margin-bottom: 1ex;
		padding-left: 1em;

		.back-button {
			font-weight: 300;

			i {
				margin-right: 0.5em;
				color: #ef9648;
				font-weight: 800;
				display: inline;
			}
		}

		.next-button {
			font-weight: 300;

			i {
				margin-left: 0.5em;
				color: #ef9648;
				font-weight: 800;
				display: inline;
			}
		}
	}



	.search-articles {
		padding: 1em;
	}

	div#stage-article-slide{
		margin-bottom: 20px;
	}

	.search-articles,
	.media-article,
	.editorial-articles,
	.stage-articles {

		.clear.main_banner{
			padding-top: 25px;
		}

		.loadMore {
			.button {
			  margin: 30px 0px;
        text-align: center;
        background-color: #ef9648;
        border-radius: 18px;
        padding: 10px 0;
        color: white;
        text-transform: uppercase;
        padding-top: 15px;
        font-size: 12px;

        span {
          margin-left: 5px;
        }

        &.loading {
          background-color: #cccccc;
        }
    	}
		}

		.articleRating {

			border-top: 1px  solid #ccc;
			border-bottom: 1px  solid #ccc;
			padding: 10px 0 5PX;
    		margin-bottom: 10px;

			.ratingLabel {
				margin-right: 10px;
			}

			.rating {
				margin-bottom: 0px;
			}
		}

		.csThumbholder {

			padding-top: 80%;
		}

		p {
			font-weight: 100;
			color: #808080;
			font-size: 1.1rem;
		}

		.more {
			font-family: universimed_1;
			text-transform: uppercase;
			font-size: 18px;

			&:before {
				content: "+";
				color: #ef9648;
				font-size: 1.3rem;
				margin-right: 0.2em;
				text-shadow: none;
				//font-weight: 800;
				font-family: universimed_1;
			}

			&:hover {
				color: #ef9648;
			}
		}

		.article-list {

			.banner_management, .long_banner{
				padding-top: 20px;
				justify-content: center;
    			display: flex;

				img {
					width: 728px !important;
				}
			}

			.article {

				margin-top: 3ex;

				border-left-color: #AAA;
				border-left-style: solid;
				border-left-width: 1.5px;
				padding-top: 15px;
				padding-bottom: 15px;
				// min-height: 450px;

				&:first-child {
					border:none;
				}

				&.no-border {
					border:none;
				}

				article {
					padding-left: 1em;
					padding-right: 1em;


					.category {
						font-size: 1.4rem;
						display: block;
						font-family: universimed_1;

						span:after {
							content: " + ";
						}

						span:last-child:after {
							content: "";
						}

						&:before {
							content: "+";
							color: #ef9648;
							font-size: 1.5rem;
							margin-right: 0.2em;
							text-shadow: none;
							//font-weight: 800;
							font-family: universimed_1;
						}
					}

					.type{
						float: left;
						font-weight: 100;
						font-size: 1rem;
						color: #aaa;
					}

					.date{
						float: right;
						font-weight: 100;
						font-size: 1rem;
						color: #aaa;
					}

					.subcategory {
						font-weight: 100;
						font-size: 1rem;
						display: block;
						color: #aaa;
					}

					h4 {
						margin-top: 1ex;
						//padding-top: 1ex;
						clear: both;
						font-family: universimed_1;

					}
				}
			}

			@media screen and (max-width: 767px) and (min-width: 360px) {
				.article{
					border: none;
					&.add{
						min-height: auto;
						// max-height: 280px;
						padding: 0;
					}
				}
			}
		}
		.carousel {

			.carousel-indicators {
				bottom: 5px;
			}

			.carousel-control-next,
			.carousel-control-prev {
				position: absolute;
				top: 0;
				bottom: 0;
				display: -ms-flexbox;
				display: flex;
				-ms-flex-align: center;
				align-items: center;
				-ms-flex-pack: center;
				justify-content: center;
				width: 15%;
				color: #fff;
				text-align: center;
				opacity: .5;

				&:focus,
				&:hover
				{
					color: #fff;
					text-decoration: none;
					outline: 0;
					opacity: .9;
				}

				i {
					font-size: 5rem;
				}

			}

			.carousel-control-prev {
				left: 0;


			}

			.carousel-control-next {
				right: 0;

			}

			.carousel-inner {
				.carousel-item {
					.carousel-caption {

						right: 15%;
						left: 15%;
						top: 11em;

						h4 {
							font-size: 4rem;
							font-family: universimed_1;
							color: white;
							line-height: 1.4;

							&:after {
								content: "";
								width: 3em;
								display: block;
								border-bottom-color: white;
								border-bottom-style: solid;
								border-bottom-width: 1.5px;
								margin-left: auto;
								margin-right: auto;
								margin-top: 0.5ex;
							}
						}

						p {
							margin-top: 3ex;
							margin-bottom: 3ex;
							color: white;
							font-family: serif;
							text-shadow: none;
							font-size: 1.2rem;
						}

						.more {
							color: white;

							&:hover {
								color: #ef9648;
							}
						}
					}
				}
			}
		}
	}


	.editorial-articles {

		&:before {
			border-top-color: #AAA;
			border-top-style: solid;
			border-top-width: 1.5px;
			margin-left: 1em;
			margin-right: 1em;
			display: block;
		}

		h3 {
			margin-top: 2ex;
			margin-bottom: 0;
			//font-weight: 600;
			font-family: universimed_1;
			font-size: 1.5rem;
		}

	}
}

.csArticle-page_wrapper{
    .group.clear,
    .group.style-image-group{
      .csMedia-left,
      .csMedia-right{
        width: 49%;
      }
    }
}

@media screen and (max-width: 1024px) and (min-width: 260px) {
	.carousel {
		.carousel-caption {
			padding-top: 0.5ex;
			padding-bottom: 0.5ex;

			.stageArticle {
				h5.teaserStrapLine {
					font-size: 4vw;
				}

				a {
					h4 {
						font-size: 5vw !important;
						margin-bottom: 0.5ex;
					}
				}

				.more {
					font-size: 3vw;
				}

			}
		}

		.carousel-control-prev,
		.carousel-control-next {
			i {
				font-size: 15vw !important;
			}
		}

		.carousel-indicators {

			margin-bottom: 0.5ex;
			bottom: 0 !important;

			li {
				width: 6px;
				height: 6px;

				margin-top: 0;
				margin-bottom: 0;
			}
		}
	}
  }

.paywall{
  padding: 2rem;
  margin: 1rem 4rem;
}

.paywall-teaser{
  position: relative;
  padding-bottom: 40px;
}
.paywall-teaser::before {
    content: "";
    position: absolute;
    height: 200px;
    width: 100%;
    background: -webkit-linear-gradient(bottom,#fff 5%,hsla(0,0%,100%,0));
    background: linear-gradient(0deg,#fff 5%,hsla(0,0%,100%,0));
    bottom: 0;
    margin-bottom: 1px;
    z-index: 100;
}

.mp-menu{
	.subMenuLink{
		color: #ccc;
		font-size: 16px;
		text-transform: uppercase;
		font-weight: 400;
		font-family: "Roboto", sans-serif;
		text-decoration: none;
		line-height: 18px;
		display: block;
		padding: 17px 20px 17px 30px;
		cursor: pointer;
		box-shadow: inset 0 -1px rgba(0, 0, 0, 0.2);
		text-shadow: 0 0 1px rgba(255, 255, 255, 0.1);
		-webkit-transition: background .3s, box-shadow .3s;
    	-moz-transition: background .3s, box-shadow .3s;
    	transition: background .3s, box-shadow .3s;
    	text-transform: uppercase;
    	outline: none;
	}

	li.active{
		.subMenuLink{
			color: #2196f3;
		}
	}
}

.newsletter-feed {

    .send_message {
        margin-top: 20px;

        .error {
            color: #F03D58;
        }
    }

    .content {
        text-align: center;
        padding   : 20px;

        a,
        button {
            font-weight : 900;
            top         : 2px;
            margin-right: 10px;
        }
    }

    .select-wrapper {
        margin-right: 12px;
        display     : inline;
    }

    input {
        background   : #fff !important;
        max-width    : 20em;
        border-radius: 6em;
        padding-left : 20px !important;
        padding-right: 20px !important;
        height       : 2rem;

        &.week {
            border       : none;
            color        : #757575;
            margin-right : 20px;
            border-bottom: 1px solid #ccc;

            &::placeholder {
                /* Chrome, Firefox, Opera, Safari 10.1+ */
                color  : #757575;
                opacity: 1;
                /* Firefox */
            }

            &:-ms-input-placeholder {
                /* Internet Explorer 10-11 */
                color: #757575;
            }

            ::-ms-input-placeholder {
                /* Microsoft Edge */
                color: #757575;
            }
        }

        &.select-dropdown {
            color : #757575;
            height: 2rem;
        }
    }

    span.caret {
        right    : 16px;
        font-size: 0;
        top      : 3px;
        padding  : 0rem 0.7rem 0.2rem 0.7rem;
        z-index  : 10;

        &::before {
            font-family   : FontAwesome;
            // font-family: 'Proxima Nova Alt Rg';
            content       : "\f078";
            color         : #757575;
            font-size     : 10px;
        }
    }

}